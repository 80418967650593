export const Accordion = () => import('../../components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const AvatarCard = () => import('../../components/AvatarCard.vue' /* webpackChunkName: "components/avatar-card" */).then(c => wrapFunctional(c.default || c))
export const BreakBanner = () => import('../../components/BreakBanner.vue' /* webpackChunkName: "components/break-banner" */).then(c => wrapFunctional(c.default || c))
export const CancelMessageDialog = () => import('../../components/CancelMessageDialog.vue' /* webpackChunkName: "components/cancel-message-dialog" */).then(c => wrapFunctional(c.default || c))
export const CloseXButton = () => import('../../components/CloseXButton.vue' /* webpackChunkName: "components/close-x-button" */).then(c => wrapFunctional(c.default || c))
export const CourseDetailsCard = () => import('../../components/CourseDetailsCard.vue' /* webpackChunkName: "components/course-details-card" */).then(c => wrapFunctional(c.default || c))
export const CourseInformationCard = () => import('../../components/CourseInformationCard.vue' /* webpackChunkName: "components/course-information-card" */).then(c => wrapFunctional(c.default || c))
export const DateButton = () => import('../../components/DateButton.vue' /* webpackChunkName: "components/date-button" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DateTab = () => import('../../components/DateTab.vue' /* webpackChunkName: "components/date-tab" */).then(c => wrapFunctional(c.default || c))
export const DialogCard = () => import('../../components/DialogCard.vue' /* webpackChunkName: "components/dialog-card" */).then(c => wrapFunctional(c.default || c))
export const DocList = () => import('../../components/DocList.vue' /* webpackChunkName: "components/doc-list" */).then(c => wrapFunctional(c.default || c))
export const DocListSplit = () => import('../../components/DocListSplit.vue' /* webpackChunkName: "components/doc-list-split" */).then(c => wrapFunctional(c.default || c))
export const DocListTutorePoland = () => import('../../components/DocListTutorePoland.vue' /* webpackChunkName: "components/doc-list-tutore-poland" */).then(c => wrapFunctional(c.default || c))
export const DocListTutoreSmall = () => import('../../components/DocListTutoreSmall.vue' /* webpackChunkName: "components/doc-list-tutore-small" */).then(c => wrapFunctional(c.default || c))
export const Droplist = () => import('../../components/Droplist.vue' /* webpackChunkName: "components/droplist" */).then(c => wrapFunctional(c.default || c))
export const Enroll = () => import('../../components/Enroll.vue' /* webpackChunkName: "components/enroll" */).then(c => wrapFunctional(c.default || c))
export const EnrollTimer = () => import('../../components/EnrollTimer.vue' /* webpackChunkName: "components/enroll-timer" */).then(c => wrapFunctional(c.default || c))
export const FormattedText = () => import('../../components/FormattedText.vue' /* webpackChunkName: "components/formatted-text" */).then(c => wrapFunctional(c.default || c))
export const GoToTeacherRegistrationDialog = () => import('../../components/GoToTeacherRegistrationDialog.vue' /* webpackChunkName: "components/go-to-teacher-registration-dialog" */).then(c => wrapFunctional(c.default || c))
export const GradeElement = () => import('../../components/GradeElement.vue' /* webpackChunkName: "components/grade-element" */).then(c => wrapFunctional(c.default || c))
export const GradeSelector = () => import('../../components/GradeSelector.vue' /* webpackChunkName: "components/grade-selector" */).then(c => wrapFunctional(c.default || c))
export const HourTab = () => import('../../components/HourTab.vue' /* webpackChunkName: "components/hour-tab" */).then(c => wrapFunctional(c.default || c))
export const InformationWithIconBlock = () => import('../../components/InformationWithIconBlock.vue' /* webpackChunkName: "components/information-with-icon-block" */).then(c => wrapFunctional(c.default || c))
export const InformationWithImageBlock = () => import('../../components/InformationWithImageBlock.vue' /* webpackChunkName: "components/information-with-image-block" */).then(c => wrapFunctional(c.default || c))
export const LocaleChanger = () => import('../../components/LocaleChanger.vue' /* webpackChunkName: "components/locale-changer" */).then(c => wrapFunctional(c.default || c))
export const MessageBlock = () => import('../../components/MessageBlock.vue' /* webpackChunkName: "components/message-block" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NotificationDialog = () => import('../../components/NotificationDialog.vue' /* webpackChunkName: "components/notification-dialog" */).then(c => wrapFunctional(c.default || c))
export const PasswordEdit = () => import('../../components/PasswordEdit.vue' /* webpackChunkName: "components/password-edit" */).then(c => wrapFunctional(c.default || c))
export const PersonalAddressData = () => import('../../components/PersonalAddressData.vue' /* webpackChunkName: "components/personal-address-data" */).then(c => wrapFunctional(c.default || c))
export const PersonalBanner = () => import('../../components/PersonalBanner.vue' /* webpackChunkName: "components/personal-banner" */).then(c => wrapFunctional(c.default || c))
export const PersonalContactData = () => import('../../components/PersonalContactData.vue' /* webpackChunkName: "components/personal-contact-data" */).then(c => wrapFunctional(c.default || c))
export const PersonalData = () => import('../../components/PersonalData.vue' /* webpackChunkName: "components/personal-data" */).then(c => wrapFunctional(c.default || c))
export const PickedDatas = () => import('../../components/PickedDatas.vue' /* webpackChunkName: "components/picked-datas" */).then(c => wrapFunctional(c.default || c))
export const PickedDatasFull = () => import('../../components/PickedDatasFull.vue' /* webpackChunkName: "components/picked-datas-full" */).then(c => wrapFunctional(c.default || c))
export const PickedDatasHeader = () => import('../../components/PickedDatasHeader.vue' /* webpackChunkName: "components/picked-datas-header" */).then(c => wrapFunctional(c.default || c))
export const PickedNoDatas = () => import('../../components/PickedNoDatas.vue' /* webpackChunkName: "components/picked-no-datas" */).then(c => wrapFunctional(c.default || c))
export const ProductCardGroup = () => import('../../components/ProductCardGroup.vue' /* webpackChunkName: "components/product-card-group" */).then(c => wrapFunctional(c.default || c))
export const ProductCardGroupContinue = () => import('../../components/ProductCardGroupContinue.vue' /* webpackChunkName: "components/product-card-group-continue" */).then(c => wrapFunctional(c.default || c))
export const ProductCardIndividual = () => import('../../components/ProductCardIndividual.vue' /* webpackChunkName: "components/product-card-individual" */).then(c => wrapFunctional(c.default || c))
export const ProductCardOrderContact = () => import('../../components/ProductCardOrderContact.vue' /* webpackChunkName: "components/product-card-order-contact" */).then(c => wrapFunctional(c.default || c))
export const ProfileCard = () => import('../../components/ProfileCard.vue' /* webpackChunkName: "components/profile-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileCardNew = () => import('../../components/ProfileCardNew.vue' /* webpackChunkName: "components/profile-card-new" */).then(c => wrapFunctional(c.default || c))
export const ProfileTab = () => import('../../components/ProfileTab.vue' /* webpackChunkName: "components/profile-tab" */).then(c => wrapFunctional(c.default || c))
export const ProfileTabNew = () => import('../../components/ProfileTabNew.vue' /* webpackChunkName: "components/profile-tab-new" */).then(c => wrapFunctional(c.default || c))
export const QuestionBlock = () => import('../../components/QuestionBlock.vue' /* webpackChunkName: "components/question-block" */).then(c => wrapFunctional(c.default || c))
export const SafeHtml = () => import('../../components/SafeHtml.vue' /* webpackChunkName: "components/safe-html" */).then(c => wrapFunctional(c.default || c))
export const ScreenLoader = () => import('../../components/ScreenLoader.vue' /* webpackChunkName: "components/screen-loader" */).then(c => wrapFunctional(c.default || c))
export const Selector = () => import('../../components/Selector.vue' /* webpackChunkName: "components/selector" */).then(c => wrapFunctional(c.default || c))
export const ServiceElement = () => import('../../components/ServiceElement.vue' /* webpackChunkName: "components/service-element" */).then(c => wrapFunctional(c.default || c))
export const ServicesBlock = () => import('../../components/ServicesBlock.vue' /* webpackChunkName: "components/services-block" */).then(c => wrapFunctional(c.default || c))
export const StarsRank = () => import('../../components/StarsRank.vue' /* webpackChunkName: "components/stars-rank" */).then(c => wrapFunctional(c.default || c))
export const Stepper = () => import('../../components/Stepper.vue' /* webpackChunkName: "components/stepper" */).then(c => wrapFunctional(c.default || c))
export const Switcher = () => import('../../components/Switcher.vue' /* webpackChunkName: "components/switcher" */).then(c => wrapFunctional(c.default || c))
export const Timer = () => import('../../components/Timer.vue' /* webpackChunkName: "components/timer" */).then(c => wrapFunctional(c.default || c))
export const Toolbar = () => import('../../components/Toolbar.vue' /* webpackChunkName: "components/toolbar" */).then(c => wrapFunctional(c.default || c))
export const ToolbarSecondary = () => import('../../components/ToolbarSecondary.vue' /* webpackChunkName: "components/toolbar-secondary" */).then(c => wrapFunctional(c.default || c))
export const VideoDialog = () => import('../../components/VideoDialog.vue' /* webpackChunkName: "components/video-dialog" */).then(c => wrapFunctional(c.default || c))
export const WelcomeDashboardPopup = () => import('../../components/WelcomeDashboardPopup.vue' /* webpackChunkName: "components/welcome-dashboard-popup" */).then(c => wrapFunctional(c.default || c))
export const AvailabilityFormChooseDate = () => import('../../components/availability_form/ChooseDate.vue' /* webpackChunkName: "components/availability-form-choose-date" */).then(c => wrapFunctional(c.default || c))
export const AvailabilityFormChooseHour = () => import('../../components/availability_form/ChooseHour.vue' /* webpackChunkName: "components/availability-form-choose-hour" */).then(c => wrapFunctional(c.default || c))
export const AvailabilityFormDateTab = () => import('../../components/availability_form/DateTab.vue' /* webpackChunkName: "components/availability-form-date-tab" */).then(c => wrapFunctional(c.default || c))
export const AvailabilityFormSaveBlock = () => import('../../components/availability_form/SaveBlock.vue' /* webpackChunkName: "components/availability-form-save-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsBiggerBanner = () => import('../../components/billings/BiggerBanner.vue' /* webpackChunkName: "components/billings-bigger-banner" */).then(c => wrapFunctional(c.default || c))
export const BillingsBottomNavigation = () => import('../../components/billings/BottomNavigation.vue' /* webpackChunkName: "components/billings-bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const BillingsEmptyState = () => import('../../components/billings/EmptyState.vue' /* webpackChunkName: "components/billings-empty-state" */).then(c => wrapFunctional(c.default || c))
export const BillingsFilterBlock = () => import('../../components/billings/FilterBlock.vue' /* webpackChunkName: "components/billings-filter-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsFullscreenBanner = () => import('../../components/billings/FullscreenBanner.vue' /* webpackChunkName: "components/billings-fullscreen-banner" */).then(c => wrapFunctional(c.default || c))
export const BillingsHistoryPaymentsFilterBlock = () => import('../../components/billings/HistoryPaymentsFilterBlock.vue' /* webpackChunkName: "components/billings-history-payments-filter-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsHistorySettlementsFilterBlock = () => import('../../components/billings/HistorySettlementsFilterBlock.vue' /* webpackChunkName: "components/billings-history-settlements-filter-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsPaymentBlock = () => import('../../components/billings/PaymentBlock.vue' /* webpackChunkName: "components/billings-payment-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsPaymentsBlock = () => import('../../components/billings/PaymentsBlock.vue' /* webpackChunkName: "components/billings-payments-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsPaymentsTable = () => import('../../components/billings/PaymentsTable.vue' /* webpackChunkName: "components/billings-payments-table" */).then(c => wrapFunctional(c.default || c))
export const BillingsSettlementsData = () => import('../../components/billings/SettlementsData.vue' /* webpackChunkName: "components/billings-settlements-data" */).then(c => wrapFunctional(c.default || c))
export const BillingsSettlementsQuestionBlock = () => import('../../components/billings/SettlementsQuestionBlock.vue' /* webpackChunkName: "components/billings-settlements-question-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsSmallerBanner = () => import('../../components/billings/SmallerBanner.vue' /* webpackChunkName: "components/billings-smaller-banner" */).then(c => wrapFunctional(c.default || c))
export const BillingsSummaryAmountBlock = () => import('../../components/billings/SummaryAmountBlock.vue' /* webpackChunkName: "components/billings-summary-amount-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsSummaryMonthBlock = () => import('../../components/billings/SummaryMonthBlock.vue' /* webpackChunkName: "components/billings-summary-month-block" */).then(c => wrapFunctional(c.default || c))
export const BillingsTooltip = () => import('../../components/billings/Tooltip.vue' /* webpackChunkName: "components/billings-tooltip" */).then(c => wrapFunctional(c.default || c))
export const CandidateTutorContactBlock = () => import('../../components/candidate/TutorContactBlock.vue' /* webpackChunkName: "components/candidate-tutor-contact-block" */).then(c => wrapFunctional(c.default || c))
export const CandidateTutorDescriptionBlock = () => import('../../components/candidate/TutorDescriptionBlock.vue' /* webpackChunkName: "components/candidate-tutor-description-block" */).then(c => wrapFunctional(c.default || c))
export const CandidateTutorInformationBlock = () => import('../../components/candidate/TutorInformationBlock.vue' /* webpackChunkName: "components/candidate-tutor-information-block" */).then(c => wrapFunctional(c.default || c))
export const CandidateTutorLessonsBlock = () => import('../../components/candidate/TutorLessonsBlock.vue' /* webpackChunkName: "components/candidate-tutor-lessons-block" */).then(c => wrapFunctional(c.default || c))
export const CandidateTutorSkillsBlock = () => import('../../components/candidate/TutorSkillsBlock.vue' /* webpackChunkName: "components/candidate-tutor-skills-block" */).then(c => wrapFunctional(c.default || c))
export const CookiesCookieAccordion = () => import('../../components/cookies/CookieAccordion.vue' /* webpackChunkName: "components/cookies-cookie-accordion" */).then(c => wrapFunctional(c.default || c))
export const CookiesCookieBanner = () => import('../../components/cookies/CookieBanner.vue' /* webpackChunkName: "components/cookies-cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const CookiesCookieDialog = () => import('../../components/cookies/CookieDialog.vue' /* webpackChunkName: "components/cookies-cookie-dialog" */).then(c => wrapFunctional(c.default || c))
export const Cookies = () => import('../../components/cookies/cookies.ts' /* webpackChunkName: "components/cookies" */).then(c => wrapFunctional(c.default || c))
export const CoursesCourseBlock = () => import('../../components/courses/CourseBlock.vue' /* webpackChunkName: "components/courses-course-block" */).then(c => wrapFunctional(c.default || c))
export const CoursesCourseCard = () => import('../../components/courses/CourseCard.vue' /* webpackChunkName: "components/courses-course-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesCourseOffer = () => import('../../components/courses/CourseOffer.vue' /* webpackChunkName: "components/courses-course-offer" */).then(c => wrapFunctional(c.default || c))
export const CoursesLessonCard = () => import('../../components/courses/LessonCard.vue' /* webpackChunkName: "components/courses-lesson-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesLessonCardFull = () => import('../../components/courses/LessonCardFull.vue' /* webpackChunkName: "components/courses-lesson-card-full" */).then(c => wrapFunctional(c.default || c))
export const CoursesLessonCardModal = () => import('../../components/courses/LessonCardModal.vue' /* webpackChunkName: "components/courses-lesson-card-modal" */).then(c => wrapFunctional(c.default || c))
export const CoursesSuggestCourseCard = () => import('../../components/courses/SuggestCourseCard.vue' /* webpackChunkName: "components/courses-suggest-course-card" */).then(c => wrapFunctional(c.default || c))
export const CoursesSuggestLessonCard = () => import('../../components/courses/SuggestLessonCard.vue' /* webpackChunkName: "components/courses-suggest-lesson-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardAvatarCard = () => import('../../components/dashboard/AvatarCard.vue' /* webpackChunkName: "components/dashboard-avatar-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardAvatarsList = () => import('../../components/dashboard/AvatarsList.vue' /* webpackChunkName: "components/dashboard-avatars-list" */).then(c => wrapFunctional(c.default || c))
export const DashboardBreadCrumb = () => import('../../components/dashboard/BreadCrumb.vue' /* webpackChunkName: "components/dashboard-bread-crumb" */).then(c => wrapFunctional(c.default || c))
export const DashboardCalendar = () => import('../../components/dashboard/Calendar.vue' /* webpackChunkName: "components/dashboard-calendar" */).then(c => wrapFunctional(c.default || c))
export const DashboardCheckpointsCard = () => import('../../components/dashboard/CheckpointsCard.vue' /* webpackChunkName: "components/dashboard-checkpoints-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardContinuesSpecialOfferBlock = () => import('../../components/dashboard/ContinuesSpecialOfferBlock.vue' /* webpackChunkName: "components/dashboard-continues-special-offer-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardCountDown = () => import('../../components/dashboard/CountDown.vue' /* webpackChunkName: "components/dashboard-count-down" */).then(c => wrapFunctional(c.default || c))
export const DashboardCourseSummary = () => import('../../components/dashboard/CourseSummary.vue' /* webpackChunkName: "components/dashboard-course-summary" */).then(c => wrapFunctional(c.default || c))
export const DashboardCourseSummaryIndividual = () => import('../../components/dashboard/CourseSummaryIndividual.vue' /* webpackChunkName: "components/dashboard-course-summary-individual" */).then(c => wrapFunctional(c.default || c))
export const DashboardCourseTeacherCard = () => import('../../components/dashboard/CourseTeacherCard.vue' /* webpackChunkName: "components/dashboard-course-teacher-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeader = () => import('../../components/dashboard/DashboardHeader.vue' /* webpackChunkName: "components/dashboard-header" */).then(c => wrapFunctional(c.default || c))
export const DashboardDiplomaCard = () => import('../../components/dashboard/DiplomaCard.vue' /* webpackChunkName: "components/dashboard-diploma-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardEmptyState = () => import('../../components/dashboard/EmptyState.vue' /* webpackChunkName: "components/dashboard-empty-state" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationCard = () => import('../../components/dashboard/EvaluationCard.vue' /* webpackChunkName: "components/dashboard-evaluation-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationTab = () => import('../../components/dashboard/EvaluationTab.vue' /* webpackChunkName: "components/dashboard-evaluation-tab" */).then(c => wrapFunctional(c.default || c))
export const DashboardFileUploader = () => import('../../components/dashboard/FileUploader.vue' /* webpackChunkName: "components/dashboard-file-uploader" */).then(c => wrapFunctional(c.default || c))
export const DashboardFinishQuizzModal = () => import('../../components/dashboard/FinishQuizzModal.vue' /* webpackChunkName: "components/dashboard-finish-quizz-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardGroupCard = () => import('../../components/dashboard/GroupCard.vue' /* webpackChunkName: "components/dashboard-group-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardIndividualCard = () => import('../../components/dashboard/IndividualCard.vue' /* webpackChunkName: "components/dashboard-individual-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardLeadBaner = () => import('../../components/dashboard/LeadBaner.vue' /* webpackChunkName: "components/dashboard-lead-baner" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonToUse = () => import('../../components/dashboard/LessonToUse.vue' /* webpackChunkName: "components/dashboard-lesson-to-use" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonsDescription = () => import('../../components/dashboard/LessonsDescription.vue' /* webpackChunkName: "components/dashboard-lessons-description" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonsDescriptionIndividualNew = () => import('../../components/dashboard/LessonsDescriptionIndividualNew.vue' /* webpackChunkName: "components/dashboard-lessons-description-individual-new" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonsDescriptionIndividualNewList = () => import('../../components/dashboard/LessonsDescriptionIndividualNewList.vue' /* webpackChunkName: "components/dashboard-lessons-description-individual-new-list" */).then(c => wrapFunctional(c.default || c))
export const DashboardMaterialCard = () => import('../../components/dashboard/MaterialCard.vue' /* webpackChunkName: "components/dashboard-material-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardMediaBlock = () => import('../../components/dashboard/MediaBlock.vue' /* webpackChunkName: "components/dashboard-media-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardMediaCard = () => import('../../components/dashboard/MediaCard.vue' /* webpackChunkName: "components/dashboard-media-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardNotificationCard = () => import('../../components/dashboard/NotificationCard.vue' /* webpackChunkName: "components/dashboard-notification-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardPointsCard = () => import('../../components/dashboard/PointsCard.vue' /* webpackChunkName: "components/dashboard-points-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardPromoOfferCard = () => import('../../components/dashboard/PromoOfferCard.vue' /* webpackChunkName: "components/dashboard-promo-offer-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardQuizCard = () => import('../../components/dashboard/QuizCard.vue' /* webpackChunkName: "components/dashboard-quiz-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardQuizzCard = () => import('../../components/dashboard/QuizzCard.vue' /* webpackChunkName: "components/dashboard-quizz-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardReviewCard = () => import('../../components/dashboard/ReviewCard.vue' /* webpackChunkName: "components/dashboard-review-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardSideBar = () => import('../../components/dashboard/SideBar.vue' /* webpackChunkName: "components/dashboard-side-bar" */).then(c => wrapFunctional(c.default || c))
export const DashboardStudentProgress = () => import('../../components/dashboard/StudentProgress.vue' /* webpackChunkName: "components/dashboard-student-progress" */).then(c => wrapFunctional(c.default || c))
export const DashboardTeacherCard = () => import('../../components/dashboard/TeacherCard.vue' /* webpackChunkName: "components/dashboard-teacher-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardTimer = () => import('../../components/dashboard/Timer.vue' /* webpackChunkName: "components/dashboard-timer" */).then(c => wrapFunctional(c.default || c))
export const DashboardToolbar = () => import('../../components/dashboard/Toolbar.vue' /* webpackChunkName: "components/dashboard-toolbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopBar = () => import('../../components/dashboard/TopBar.vue' /* webpackChunkName: "components/dashboard-top-bar" */).then(c => wrapFunctional(c.default || c))
export const DashboardWorkCard = () => import('../../components/dashboard/WorkCard.vue' /* webpackChunkName: "components/dashboard-work-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollBottomNavigation = () => import('../../components/enroll/BottomNavigation.vue' /* webpackChunkName: "components/enroll-bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const EnrollChangeProfileDialog = () => import('../../components/enroll/ChangeProfileDialog.vue' /* webpackChunkName: "components/enroll-change-profile-dialog" */).then(c => wrapFunctional(c.default || c))
export const EnrollChoiceProfileBlock = () => import('../../components/enroll/ChoiceProfileBlock.vue' /* webpackChunkName: "components/enroll-choice-profile-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollChooseDates = () => import('../../components/enroll/ChooseDates.vue' /* webpackChunkName: "components/enroll-choose-dates" */).then(c => wrapFunctional(c.default || c))
export const EnrollChooseLessonTypeBlock = () => import('../../components/enroll/ChooseLessonTypeBlock.vue' /* webpackChunkName: "components/enroll-choose-lesson-type-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollChooseServices = () => import('../../components/enroll/ChooseServices.vue' /* webpackChunkName: "components/enroll-choose-services" */).then(c => wrapFunctional(c.default || c))
export const EnrollChooseServicesInformationBlock = () => import('../../components/enroll/ChooseServicesInformationBlock.vue' /* webpackChunkName: "components/enroll-choose-services-information-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollChooseServicesInformationTablet = () => import('../../components/enroll/ChooseServicesInformationTablet.vue' /* webpackChunkName: "components/enroll-choose-services-information-tablet" */).then(c => wrapFunctional(c.default || c))
export const EnrollChooseServicesPickProfile = () => import('../../components/enroll/ChooseServicesPickProfile.vue' /* webpackChunkName: "components/enroll-choose-services-pick-profile" */).then(c => wrapFunctional(c.default || c))
export const EnrollConfirmReservationCard = () => import('../../components/enroll/ConfirmReservationCard.vue' /* webpackChunkName: "components/enroll-confirm-reservation-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollCourseInformation = () => import('../../components/enroll/CourseInformation.vue' /* webpackChunkName: "components/enroll-course-information" */).then(c => wrapFunctional(c.default || c))
export const EnrollDetailsBlock = () => import('../../components/enroll/DetailsBlock.vue' /* webpackChunkName: "components/enroll-details-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollDiscountBlock = () => import('../../components/enroll/DiscountBlock.vue' /* webpackChunkName: "components/enroll-discount-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollFreeTrialReservationCard = () => import('../../components/enroll/FreeTrialReservationCard.vue' /* webpackChunkName: "components/enroll-free-trial-reservation-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollGroupCourseInformation = () => import('../../components/enroll/GroupCourseInformation.vue' /* webpackChunkName: "components/enroll-group-course-information" */).then(c => wrapFunctional(c.default || c))
export const EnrollLessonTypeBlock = () => import('../../components/enroll/LessonTypeBlock.vue' /* webpackChunkName: "components/enroll-lesson-type-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollMultiServicesInformation = () => import('../../components/enroll/MultiServicesInformation.vue' /* webpackChunkName: "components/enroll-multi-services-information" */).then(c => wrapFunctional(c.default || c))
export const EnrollNoChildPickedInformationBlock = () => import('../../components/enroll/NoChildPickedInformationBlock.vue' /* webpackChunkName: "components/enroll-no-child-picked-information-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollNoDatePickedInformationBlock = () => import('../../components/enroll/NoDatePickedInformationBlock.vue' /* webpackChunkName: "components/enroll-no-date-picked-information-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollNoDatesBlock = () => import('../../components/enroll/NoDatesBlock.vue' /* webpackChunkName: "components/enroll-no-dates-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollNormalGroupPaymentBlock = () => import('../../components/enroll/NormalGroupPaymentBlock.vue' /* webpackChunkName: "components/enroll-normal-group-payment-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollPaymentBlock = () => import('../../components/enroll/PaymentBlock.vue' /* webpackChunkName: "components/enroll-payment-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollPaymentBlockMdAndDown = () => import('../../components/enroll/PaymentBlockMdAndDown.vue' /* webpackChunkName: "components/enroll-payment-block-md-and-down" */).then(c => wrapFunctional(c.default || c))
export const EnrollPaymentMethodCard = () => import('../../components/enroll/PaymentMethodCard.vue' /* webpackChunkName: "components/enroll-payment-method-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollPickedDates = () => import('../../components/enroll/PickedDates.vue' /* webpackChunkName: "components/enroll-picked-dates" */).then(c => wrapFunctional(c.default || c))
export const EnrollServicesEnroll = () => import('../../components/enroll/ServicesEnroll.vue' /* webpackChunkName: "components/enroll-services-enroll" */).then(c => wrapFunctional(c.default || c))
export const EnrollToolbar = () => import('../../components/enroll/Toolbar.vue' /* webpackChunkName: "components/enroll-toolbar" */).then(c => wrapFunctional(c.default || c))
export const EnrollToolbarBack = () => import('../../components/enroll/ToolbarBack.vue' /* webpackChunkName: "components/enroll-toolbar-back" */).then(c => wrapFunctional(c.default || c))
export const EnrollTrialGroupPaymentBlock = () => import('../../components/enroll/TrialGroupPaymentBlock.vue' /* webpackChunkName: "components/enroll-trial-group-payment-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialCard = () => import('../../components/enroll_after_trial/AfterTrialCard.vue' /* webpackChunkName: "components/enroll-after-trial-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialDashboardBanner = () => import('../../components/enroll_after_trial/AfterTrialDashboardBanner.vue' /* webpackChunkName: "components/enroll-after-trial-dashboard-banner" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialDashboardCard = () => import('../../components/enroll_after_trial/AfterTrialDashboardCard.vue' /* webpackChunkName: "components/enroll-after-trial-dashboard-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialChooseTrialCard = () => import('../../components/enroll_after_trial/ChooseTrialCard.vue' /* webpackChunkName: "components/enroll-after-trial-choose-trial-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialSummary = () => import('../../components/enroll_after_trial/EnrollAfterTrialSummary.vue' /* webpackChunkName: "components/enroll-after-trial-summary" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialGoToPayment = () => import('../../components/enroll_after_trial/GoToPayment.vue' /* webpackChunkName: "components/enroll-after-trial-go-to-payment" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialHourTab = () => import('../../components/enroll_after_trial/HourTab.vue' /* webpackChunkName: "components/enroll-after-trial-hour-tab" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialLessonsDateChoice = () => import('../../components/enroll_after_trial/LessonsDateChoice.vue' /* webpackChunkName: "components/enroll-after-trial-lessons-date-choice" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialMultiServicesInformation = () => import('../../components/enroll_after_trial/MultiServicesInformation.vue' /* webpackChunkName: "components/enroll-after-trial-multi-services-information" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialPackageCard = () => import('../../components/enroll_after_trial/PackageCard.vue' /* webpackChunkName: "components/enroll-after-trial-package-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialPackageChoice = () => import('../../components/enroll_after_trial/PackageChoice.vue' /* webpackChunkName: "components/enroll-after-trial-package-choice" */).then(c => wrapFunctional(c.default || c))
export const EnrollAfterTrialPayerData = () => import('../../components/enroll_after_trial/PayerData.vue' /* webpackChunkName: "components/enroll-after-trial-payer-data" */).then(c => wrapFunctional(c.default || c))
export const EnrollResignationDialog = () => import('../../components/enroll_choosen_profile/EnrollResignationDialog.vue' /* webpackChunkName: "components/enroll-resignation-dialog" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesBenefitTab = () => import('../../components/enroll_continues/BenefitTab.vue' /* webpackChunkName: "components/enroll-continues-benefit-tab" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesBenefitsBlock = () => import('../../components/enroll_continues/BenefitsBlock.vue' /* webpackChunkName: "components/enroll-continues-benefits-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesCancelEnrollDialog = () => import('../../components/enroll_continues/CancelEnrollDialog.vue' /* webpackChunkName: "components/enroll-continues-cancel-enroll-dialog" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesChooseDates = () => import('../../components/enroll_continues/ChooseDates.vue' /* webpackChunkName: "components/enroll-continues-choose-dates" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesMultiServicesInformation = () => import('../../components/enroll_continues/MultiServicesInformation.vue' /* webpackChunkName: "components/enroll-continues-multi-services-information" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesPaymentBlock = () => import('../../components/enroll_continues/PaymentBlock.vue' /* webpackChunkName: "components/enroll-continues-payment-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesPaymentMethodBlock = () => import('../../components/enroll_continues/PaymentMethodBlock.vue' /* webpackChunkName: "components/enroll-continues-payment-method-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesPaymentMethodCard = () => import('../../components/enroll_continues/PaymentMethodCard.vue' /* webpackChunkName: "components/enroll-continues-payment-method-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesPaymentRenewalsCard = () => import('../../components/enroll_continues/PaymentRenewalsCard.vue' /* webpackChunkName: "components/enroll-continues-payment-renewals-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesPaymentRenewalsCardDetails = () => import('../../components/enroll_continues/PaymentRenewalsCardDetails.vue' /* webpackChunkName: "components/enroll-continues-payment-renewals-card-details" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesPickedDataBlock = () => import('../../components/enroll_continues/PickedDataBlock.vue' /* webpackChunkName: "components/enroll-continues-picked-data-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesPickedDataCard = () => import('../../components/enroll_continues/PickedDataCard.vue' /* webpackChunkName: "components/enroll-continues-picked-data-card" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesPickedDataEmptyState = () => import('../../components/enroll_continues/PickedDataEmptyState.vue' /* webpackChunkName: "components/enroll-continues-picked-data-empty-state" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesTimeBlock = () => import('../../components/enroll_continues/TimeBlock.vue' /* webpackChunkName: "components/enroll-continues-time-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollContinuesToolbar = () => import('../../components/enroll_continues/Toolbar.vue' /* webpackChunkName: "components/enroll-continues-toolbar" */).then(c => wrapFunctional(c.default || c))
export const EnrollSuccessContinuesBlock = () => import('../../components/enroll_success/ContinuesBlock.vue' /* webpackChunkName: "components/enroll-success-continues-block" */).then(c => wrapFunctional(c.default || c))
export const EnrollSuccessContinuesTab = () => import('../../components/enroll_success/ContinuesTab.vue' /* webpackChunkName: "components/enroll-success-continues-tab" */).then(c => wrapFunctional(c.default || c))
export const EnrollWithoutAccountBanner = () => import('../../components/enroll_without_account/Banner.vue' /* webpackChunkName: "components/enroll-without-account-banner" */).then(c => wrapFunctional(c.default || c))
export const EnrollWithoutAccountGradePanel = () => import('../../components/enroll_without_account/GradePanel.vue' /* webpackChunkName: "components/enroll-without-account-grade-panel" */).then(c => wrapFunctional(c.default || c))
export const EnrollWithoutAccountGradeSelector = () => import('../../components/enroll_without_account/GradeSelector.vue' /* webpackChunkName: "components/enroll-without-account-grade-selector" */).then(c => wrapFunctional(c.default || c))
export const EnrollWithoutAccountNoCoursesForProfilesDialog = () => import('../../components/enroll_without_account/NoCoursesForProfilesDialog.vue' /* webpackChunkName: "components/enroll-without-account-no-courses-for-profiles-dialog" */).then(c => wrapFunctional(c.default || c))
export const EnrollWithoutAccountNoGradeSelected = () => import('../../components/enroll_without_account/NoGradeSelected.vue' /* webpackChunkName: "components/enroll-without-account-no-grade-selected" */).then(c => wrapFunctional(c.default || c))
export const EnrollWithoutAccountNotLoginDialog = () => import('../../components/enroll_without_account/NotLoginDialog.vue' /* webpackChunkName: "components/enroll-without-account-not-login-dialog" */).then(c => wrapFunctional(c.default || c))
export const EnrollWithoutAccountServiceDisabledBlock = () => import('../../components/enroll_without_account/ServiceDisabledBlock.vue' /* webpackChunkName: "components/enroll-without-account-service-disabled-block" */).then(c => wrapFunctional(c.default || c))
export const FirstLoginBasicCard = () => import('../../components/first_login/BasicCard.vue' /* webpackChunkName: "components/first-login-basic-card" */).then(c => wrapFunctional(c.default || c))
export const FirstLoginCertificateCard = () => import('../../components/first_login/CertificateCard.vue' /* webpackChunkName: "components/first-login-certificate-card" */).then(c => wrapFunctional(c.default || c))
export const FirstLoginCounter = () => import('../../components/first_login/Counter.vue' /* webpackChunkName: "components/first-login-counter" */).then(c => wrapFunctional(c.default || c))
export const FirstLoginEducationCard = () => import('../../components/first_login/EducationCard.vue' /* webpackChunkName: "components/first-login-education-card" */).then(c => wrapFunctional(c.default || c))
export const FirstLoginExperienceCard = () => import('../../components/first_login/ExperienceCard.vue' /* webpackChunkName: "components/first-login-experience-card" */).then(c => wrapFunctional(c.default || c))
export const FirstLoginToolbar = () => import('../../components/first_login/Toolbar.vue' /* webpackChunkName: "components/first-login-toolbar" */).then(c => wrapFunctional(c.default || c))
export const HelpFaq = () => import('../../components/help/Faq.vue' /* webpackChunkName: "components/help-faq" */).then(c => wrapFunctional(c.default || c))
export const HelpMaintenanceBlock = () => import('../../components/help/MaintenanceBlock.vue' /* webpackChunkName: "components/help-maintenance-block" */).then(c => wrapFunctional(c.default || c))
export const HelpSendMessageBlock = () => import('../../components/help/SendMessageBlock.vue' /* webpackChunkName: "components/help-send-message-block" */).then(c => wrapFunctional(c.default || c))
export const HelpTutoreHeader = () => import('../../components/help/TutoreHeader.vue' /* webpackChunkName: "components/help-tutore-header" */).then(c => wrapFunctional(c.default || c))
export const HomeBannerHoliday = () => import('../../components/home/BannerHoliday.vue' /* webpackChunkName: "components/home-banner-holiday" */).then(c => wrapFunctional(c.default || c))
export const HomeCamps = () => import('../../components/home/Camps.vue' /* webpackChunkName: "components/home-camps" */).then(c => wrapFunctional(c.default || c))
export const HomeComments = () => import('../../components/home/Comments.vue' /* webpackChunkName: "components/home-comments" */).then(c => wrapFunctional(c.default || c))
export const HomeContentMenu = () => import('../../components/home/ContentMenu.vue' /* webpackChunkName: "components/home-content-menu" */).then(c => wrapFunctional(c.default || c))
export const HomeDiscoverIndividual = () => import('../../components/home/DiscoverIndividual.vue' /* webpackChunkName: "components/home-discover-individual" */).then(c => wrapFunctional(c.default || c))
export const HomeFooter = () => import('../../components/home/Footer.vue' /* webpackChunkName: "components/home-footer" */).then(c => wrapFunctional(c.default || c))
export const HomeHeaderMenu = () => import('../../components/home/HeaderMenu.vue' /* webpackChunkName: "components/home-header-menu" */).then(c => wrapFunctional(c.default || c))
export const HomeHoliday = () => import('../../components/home/Holiday.vue' /* webpackChunkName: "components/home-holiday" */).then(c => wrapFunctional(c.default || c))
export const HomeHowItWork = () => import('../../components/home/HowItWork.vue' /* webpackChunkName: "components/home-how-it-work" */).then(c => wrapFunctional(c.default || c))
export const HomeRecommendedCourseCard = () => import('../../components/home/RecommendedCourseCard.vue' /* webpackChunkName: "components/home-recommended-course-card" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherBanner = () => import('../../components/home/TeacherBanner.vue' /* webpackChunkName: "components/home-teacher-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeTestLesson = () => import('../../components/home/TestLesson.vue' /* webpackChunkName: "components/home-test-lesson" */).then(c => wrapFunctional(c.default || c))
export const HomeWithTeacher = () => import('../../components/home/WithTeacher.vue' /* webpackChunkName: "components/home-with-teacher" */).then(c => wrapFunctional(c.default || c))
export const LeadOfferNewAskAboutServiceForm = () => import('../../components/lead_offer_new/AskAboutServiceForm.vue' /* webpackChunkName: "components/lead-offer-new-ask-about-service-form" */).then(c => wrapFunctional(c.default || c))
export const LeadOfferNewBenefit = () => import('../../components/lead_offer_new/Benefit.vue' /* webpackChunkName: "components/lead-offer-new-benefit" */).then(c => wrapFunctional(c.default || c))
export const LeadOfferNewBenefitsBanner = () => import('../../components/lead_offer_new/BenefitsBanner.vue' /* webpackChunkName: "components/lead-offer-new-benefits-banner" */).then(c => wrapFunctional(c.default || c))
export const LeadOfferNewBenefitsBlock = () => import('../../components/lead_offer_new/BenefitsBlock.vue' /* webpackChunkName: "components/lead-offer-new-benefits-block" */).then(c => wrapFunctional(c.default || c))
export const LeadOfferNewComments = () => import('../../components/lead_offer_new/Comments.vue' /* webpackChunkName: "components/lead-offer-new-comments" */).then(c => wrapFunctional(c.default || c))
export const LeadBanner = () => import('../../components/lead_offer_new/LeadBanner.vue' /* webpackChunkName: "components/lead-banner" */).then(c => wrapFunctional(c.default || c))
export const LeadOfferNewOpinionSection = () => import('../../components/lead_offer_new/OpinionSection.vue' /* webpackChunkName: "components/lead-offer-new-opinion-section" */).then(c => wrapFunctional(c.default || c))
export const LeadOfferNewProductCardOrderContactSmall = () => import('../../components/lead_offer_new/ProductCardOrderContactSmall.vue' /* webpackChunkName: "components/lead-offer-new-product-card-order-contact-small" */).then(c => wrapFunctional(c.default || c))
export const LeadOfferNewServiceBlock = () => import('../../components/lead_offer_new/ServiceBlock.vue' /* webpackChunkName: "components/lead-offer-new-service-block" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberAskAboutServiceBanner = () => import('../../components/member_gets_member/AskAboutServiceBanner.vue' /* webpackChunkName: "components/member-gets-member-ask-about-service-banner" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberAskAboutServiceForm = () => import('../../components/member_gets_member/AskAboutServiceForm.vue' /* webpackChunkName: "components/member-gets-member-ask-about-service-form" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberFooter = () => import('../../components/member_gets_member/Footer.vue' /* webpackChunkName: "components/member-gets-member-footer" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberHowItWorksBlock = () => import('../../components/member_gets_member/HowItWorksBlock.vue' /* webpackChunkName: "components/member-gets-member-how-it-works-block" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberLeadSubmitSuccess = () => import('../../components/member_gets_member/LeadSubmitSuccess.vue' /* webpackChunkName: "components/member-gets-member-lead-submit-success" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberProductCardOrderContact = () => import('../../components/member_gets_member/ProductCardOrderContact.vue' /* webpackChunkName: "components/member-gets-member-product-card-order-contact" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberProductCardOrderContactSmall = () => import('../../components/member_gets_member/ProductCardOrderContactSmall.vue' /* webpackChunkName: "components/member-gets-member-product-card-order-contact-small" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberPromoCardClient = () => import('../../components/member_gets_member/PromoCardClient.vue' /* webpackChunkName: "components/member-gets-member-promo-card-client" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberPromoCardGuest = () => import('../../components/member_gets_member/PromoCardGuest.vue' /* webpackChunkName: "components/member-gets-member-promo-card-guest" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberPromoOfferBanner = () => import('../../components/member_gets_member/PromoOfferBanner.vue' /* webpackChunkName: "components/member-gets-member-promo-offer-banner" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberPromoShareSocials = () => import('../../components/member_gets_member/PromoShareSocials.vue' /* webpackChunkName: "components/member-gets-member-promo-share-socials" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferFriendCard = () => import('../../components/member_gets_member/ReferFriendCard.vue' /* webpackChunkName: "components/member-gets-member-refer-friend-card" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferralPopup = () => import('../../components/member_gets_member/ReferralPopup.vue' /* webpackChunkName: "components/member-gets-member-referral-popup" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferralStatus = () => import('../../components/member_gets_member/ReferralStatus.vue' /* webpackChunkName: "components/member-gets-member-referral-status" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferredSummaryTable = () => import('../../components/member_gets_member/ReferredSummaryTable.vue' /* webpackChunkName: "components/member-gets-member-referred-summary-table" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberWhoToInvite = () => import('../../components/member_gets_member/WhoToInvite.vue' /* webpackChunkName: "components/member-gets-member-who-to-invite" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberWhoToInviteBlock = () => import('../../components/member_gets_member/WhoToInviteBlock.vue' /* webpackChunkName: "components/member-gets-member-who-to-invite-block" */).then(c => wrapFunctional(c.default || c))
export const MessengerConversationBlock = () => import('../../components/messenger/ConversationBlock.vue' /* webpackChunkName: "components/messenger-conversation-block" */).then(c => wrapFunctional(c.default || c))
export const MessengerConversationHeader = () => import('../../components/messenger/ConversationHeader.vue' /* webpackChunkName: "components/messenger-conversation-header" */).then(c => wrapFunctional(c.default || c))
export const MessengerConversationsTab = () => import('../../components/messenger/ConversationsTab.vue' /* webpackChunkName: "components/messenger-conversations-tab" */).then(c => wrapFunctional(c.default || c))
export const MessengerMessageTab = () => import('../../components/messenger/MessageTab.vue' /* webpackChunkName: "components/messenger-message-tab" */).then(c => wrapFunctional(c.default || c))
export const MessengerMessagesBlock = () => import('../../components/messenger/MessagesBlock.vue' /* webpackChunkName: "components/messenger-messages-block" */).then(c => wrapFunctional(c.default || c))
export const MessengerSendMessageBlock = () => import('../../components/messenger/SendMessageBlock.vue' /* webpackChunkName: "components/messenger-send-message-block" */).then(c => wrapFunctional(c.default || c))
export const MessengerServiceBlock = () => import('../../components/messenger/ServiceBlock.vue' /* webpackChunkName: "components/messenger-service-block" */).then(c => wrapFunctional(c.default || c))
export const MessengerServiceInformationBlock = () => import('../../components/messenger/ServiceInformationBlock.vue' /* webpackChunkName: "components/messenger-service-information-block" */).then(c => wrapFunctional(c.default || c))
export const OfferBannerRegister = () => import('../../components/offer/BannerRegister.vue' /* webpackChunkName: "components/offer-banner-register" */).then(c => wrapFunctional(c.default || c))
export const OfferBannerTestimonials = () => import('../../components/offer/BannerTestimonials.vue' /* webpackChunkName: "components/offer-banner-testimonials" */).then(c => wrapFunctional(c.default || c))
export const OnboardingChooseDate = () => import('../../components/onboarding/ChooseDate.vue' /* webpackChunkName: "components/onboarding-choose-date" */).then(c => wrapFunctional(c.default || c))
export const OnboardingChooseHour = () => import('../../components/onboarding/ChooseHour.vue' /* webpackChunkName: "components/onboarding-choose-hour" */).then(c => wrapFunctional(c.default || c))
export const OnboardingDataBox = () => import('../../components/onboarding/DataBox.vue' /* webpackChunkName: "components/onboarding-data-box" */).then(c => wrapFunctional(c.default || c))
export const OnboardingDataTab = () => import('../../components/onboarding/DataTab.vue' /* webpackChunkName: "components/onboarding-data-tab" */).then(c => wrapFunctional(c.default || c))
export const OnboardingDateTab = () => import('../../components/onboarding/DateTab.vue' /* webpackChunkName: "components/onboarding-date-tab" */).then(c => wrapFunctional(c.default || c))
export const OnboardingPhotoTips = () => import('../../components/onboarding/PhotoTips.vue' /* webpackChunkName: "components/onboarding-photo-tips" */).then(c => wrapFunctional(c.default || c))
export const OnboardingPickedDatasBlock = () => import('../../components/onboarding/PickedDatasBlock.vue' /* webpackChunkName: "components/onboarding-picked-datas-block" */).then(c => wrapFunctional(c.default || c))
export const OnboardingSummaryBox = () => import('../../components/onboarding/SummaryBox.vue' /* webpackChunkName: "components/onboarding-summary-box" */).then(c => wrapFunctional(c.default || c))
export const OnboardingToolbar = () => import('../../components/onboarding/Toolbar.vue' /* webpackChunkName: "components/onboarding-toolbar" */).then(c => wrapFunctional(c.default || c))
export const OnboardingVideoDeleteDialog = () => import('../../components/onboarding/VideoDeleteDialog.vue' /* webpackChunkName: "components/onboarding-video-delete-dialog" */).then(c => wrapFunctional(c.default || c))
export const OnboardingVideoDialog = () => import('../../components/onboarding/VideoDialog.vue' /* webpackChunkName: "components/onboarding-video-dialog" */).then(c => wrapFunctional(c.default || c))
export const OnboardingVideoTips = () => import('../../components/onboarding/VideoTips.vue' /* webpackChunkName: "components/onboarding-video-tips" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldChooseDate = () => import('../../components/onboarding_old/ChooseDate.vue' /* webpackChunkName: "components/onboarding-old-choose-date" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldChooseHour = () => import('../../components/onboarding_old/ChooseHour.vue' /* webpackChunkName: "components/onboarding-old-choose-hour" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldDataBox = () => import('../../components/onboarding_old/DataBox.vue' /* webpackChunkName: "components/onboarding-old-data-box" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldDataTab = () => import('../../components/onboarding_old/DataTab.vue' /* webpackChunkName: "components/onboarding-old-data-tab" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldDateTab = () => import('../../components/onboarding_old/DateTab.vue' /* webpackChunkName: "components/onboarding-old-date-tab" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldPhotoTips = () => import('../../components/onboarding_old/PhotoTips.vue' /* webpackChunkName: "components/onboarding-old-photo-tips" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldPickedDatasBlock = () => import('../../components/onboarding_old/PickedDatasBlock.vue' /* webpackChunkName: "components/onboarding-old-picked-datas-block" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldSummaryBox = () => import('../../components/onboarding_old/SummaryBox.vue' /* webpackChunkName: "components/onboarding-old-summary-box" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldToolbar = () => import('../../components/onboarding_old/Toolbar.vue' /* webpackChunkName: "components/onboarding-old-toolbar" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldVideoDialog = () => import('../../components/onboarding_old/VideoDialog.vue' /* webpackChunkName: "components/onboarding-old-video-dialog" */).then(c => wrapFunctional(c.default || c))
export const OnboardingOldVideoTips = () => import('../../components/onboarding_old/VideoTips.vue' /* webpackChunkName: "components/onboarding-old-video-tips" */).then(c => wrapFunctional(c.default || c))
export const OneByOneBottomBar = () => import('../../components/one_by_one/BottomBar.vue' /* webpackChunkName: "components/one-by-one-bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const OneByOneDatesStep = () => import('../../components/one_by_one/DatesStep.vue' /* webpackChunkName: "components/one-by-one-dates-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneEditStep = () => import('../../components/one_by_one/EditStep.vue' /* webpackChunkName: "components/one-by-one-edit-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneExistingAccountCheckEmailScreen = () => import('../../components/one_by_one/ExistingAccountCheckEmailScreen.vue' /* webpackChunkName: "components/one-by-one-existing-account-check-email-screen" */).then(c => wrapFunctional(c.default || c))
export const OneByOneExistingAccountDialog = () => import('../../components/one_by_one/ExistingAccountDialog.vue' /* webpackChunkName: "components/one-by-one-existing-account-dialog" */).then(c => wrapFunctional(c.default || c))
export const OneByOneFormStep = () => import('../../components/one_by_one/FormStep.vue' /* webpackChunkName: "components/one-by-one-form-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneLoginDialog = () => import('../../components/one_by_one/LoginDialog.vue' /* webpackChunkName: "components/one-by-one-login-dialog" */).then(c => wrapFunctional(c.default || c))
export const OneByOneNameStep = () => import('../../components/one_by_one/NameStep.vue' /* webpackChunkName: "components/one-by-one-name-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOnePackageButton = () => import('../../components/one_by_one/PackageButton.vue' /* webpackChunkName: "components/one-by-one-package-button" */).then(c => wrapFunctional(c.default || c))
export const OneByOnePackageInformation = () => import('../../components/one_by_one/PackageInformation.vue' /* webpackChunkName: "components/one-by-one-package-information" */).then(c => wrapFunctional(c.default || c))
export const OneByOnePackageStep = () => import('../../components/one_by_one/PackageStep.vue' /* webpackChunkName: "components/one-by-one-package-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOnePaymentFailed = () => import('../../components/one_by_one/PaymentFailed.vue' /* webpackChunkName: "components/one-by-one-payment-failed" */).then(c => wrapFunctional(c.default || c))
export const OneByOnePaymentSuccess = () => import('../../components/one_by_one/PaymentSuccess.vue' /* webpackChunkName: "components/one-by-one-payment-success" */).then(c => wrapFunctional(c.default || c))
export const OneByOnePaymentSuccessMonth = () => import('../../components/one_by_one/PaymentSuccessMonth.vue' /* webpackChunkName: "components/one-by-one-payment-success-month" */).then(c => wrapFunctional(c.default || c))
export const OneByOnePaymentSuccessTrial = () => import('../../components/one_by_one/PaymentSuccessTrial.vue' /* webpackChunkName: "components/one-by-one-payment-success-trial" */).then(c => wrapFunctional(c.default || c))
export const OneByOnePhone = () => import('../../components/one_by_one/Phone.vue' /* webpackChunkName: "components/one-by-one-phone" */).then(c => wrapFunctional(c.default || c))
export const OneByOneProfileStep = () => import('../../components/one_by_one/ProfileStep.vue' /* webpackChunkName: "components/one-by-one-profile-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneProfileTab = () => import('../../components/one_by_one/ProfileTab.vue' /* webpackChunkName: "components/one-by-one-profile-tab" */).then(c => wrapFunctional(c.default || c))
export const OneByOneQuestionsStep = () => import('../../components/one_by_one/QuestionsStep.vue' /* webpackChunkName: "components/one-by-one-questions-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneScheduleBuyPackageStep = () => import('../../components/one_by_one/ScheduleBuyPackageStep.vue' /* webpackChunkName: "components/one-by-one-schedule-buy-package-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneSchedulePackageStep = () => import('../../components/one_by_one/SchedulePackageStep.vue' /* webpackChunkName: "components/one-by-one-schedule-package-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneScheduleStep = () => import('../../components/one_by_one/ScheduleStep.vue' /* webpackChunkName: "components/one-by-one-schedule-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneSummary = () => import('../../components/one_by_one/Summary.vue' /* webpackChunkName: "components/one-by-one-summary" */).then(c => wrapFunctional(c.default || c))
export const OneByOneTeacherCard = () => import('../../components/one_by_one/TeacherCard.vue' /* webpackChunkName: "components/one-by-one-teacher-card" */).then(c => wrapFunctional(c.default || c))
export const OneByOneTeacherCardSmall = () => import('../../components/one_by_one/TeacherCardSmall.vue' /* webpackChunkName: "components/one-by-one-teacher-card-small" */).then(c => wrapFunctional(c.default || c))
export const OneByOneTeacherStep = () => import('../../components/one_by_one/TeacherStep.vue' /* webpackChunkName: "components/one-by-one-teacher-step" */).then(c => wrapFunctional(c.default || c))
export const OneByOneTextDivider = () => import('../../components/one_by_one/TextDivider.vue' /* webpackChunkName: "components/one-by-one-text-divider" */).then(c => wrapFunctional(c.default || c))
export const OneByOneTimer = () => import('../../components/one_by_one/Timer.vue' /* webpackChunkName: "components/one-by-one-timer" */).then(c => wrapFunctional(c.default || c))
export const OneByOneTimerDialog = () => import('../../components/one_by_one/TimerDialog.vue' /* webpackChunkName: "components/one-by-one-timer-dialog" */).then(c => wrapFunctional(c.default || c))
export const OneByOneToolbar = () => import('../../components/one_by_one/Toolbar.vue' /* webpackChunkName: "components/one-by-one-toolbar" */).then(c => wrapFunctional(c.default || c))
export const OneByOneTopBar = () => import('../../components/one_by_one/TopBar.vue' /* webpackChunkName: "components/one-by-one-top-bar" */).then(c => wrapFunctional(c.default || c))
export const PaymentCancelFormDialog = () => import('../../components/payment/CancelFormDialog.vue' /* webpackChunkName: "components/payment-cancel-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const PaymentIndividualPaymentCard = () => import('../../components/payment/IndividualPaymentCard.vue' /* webpackChunkName: "components/payment-individual-payment-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentOtpDialog = () => import('../../components/payment/OtpDialog.vue' /* webpackChunkName: "components/payment-otp-dialog" */).then(c => wrapFunctional(c.default || c))
export const PaymentPayerForm = () => import('../../components/payment/PayerForm.vue' /* webpackChunkName: "components/payment-payer-form" */).then(c => wrapFunctional(c.default || c))
export const PaymentCard = () => import('../../components/payment/PaymentCard.vue' /* webpackChunkName: "components/payment-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentPromoCodeSection = () => import('../../components/payment/PaymentPromoCodeSection.vue' /* webpackChunkName: "components/payment-promo-code-section" */).then(c => wrapFunctional(c.default || c))
export const PaymentStepper = () => import('../../components/payment/PaymentStepper.vue' /* webpackChunkName: "components/payment-stepper" */).then(c => wrapFunctional(c.default || c))
export const PaymentToolbar = () => import('../../components/payment/Toolbar.vue' /* webpackChunkName: "components/payment-toolbar" */).then(c => wrapFunctional(c.default || c))
export const PaymentPageBenefitTab = () => import('../../components/payment_page/BenefitTab.vue' /* webpackChunkName: "components/payment-page-benefit-tab" */).then(c => wrapFunctional(c.default || c))
export const PaymentPageBenefitsBlock = () => import('../../components/payment_page/BenefitsBlock.vue' /* webpackChunkName: "components/payment-page-benefits-block" */).then(c => wrapFunctional(c.default || c))
export const PaymentGroupCard = () => import('../../components/payment_page/PaymentGroupCard.vue' /* webpackChunkName: "components/payment-group-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentGroupCardDetails = () => import('../../components/payment_page/PaymentGroupCardDetails.vue' /* webpackChunkName: "components/payment-group-card-details" */).then(c => wrapFunctional(c.default || c))
export const PaymentServiceGroupCourseInformation = () => import('../../components/payment_service_group/GroupCourseInformation.vue' /* webpackChunkName: "components/payment-service-group-course-information" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodBlock = () => import('../../components/payment_service_group/PaymentMethodBlock.vue' /* webpackChunkName: "components/payment-method-block" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodCard = () => import('../../components/payment_service_group/PaymentMethodCard.vue' /* webpackChunkName: "components/payment-method-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentSummaryBlock = () => import('../../components/payment_service_group/PaymentSummaryBlock.vue' /* webpackChunkName: "components/payment-summary-block" */).then(c => wrapFunctional(c.default || c))
export const PaymentServiceGroupToolbar = () => import('../../components/payment_service_group/Toolbar.vue' /* webpackChunkName: "components/payment-service-group-toolbar" */).then(c => wrapFunctional(c.default || c))
export const PinsOtpInput = () => import('../../components/pins/OtpInput.vue' /* webpackChunkName: "components/pins-otp-input" */).then(c => wrapFunctional(c.default || c))
export const PinsSecurityBlock = () => import('../../components/pins/SecurityBlock.vue' /* webpackChunkName: "components/pins-security-block" */).then(c => wrapFunctional(c.default || c))
export const PrivacyCookieParagraphs = () => import('../../components/privacy-cookie/Paragraphs.vue' /* webpackChunkName: "components/privacy-cookie-paragraphs" */).then(c => wrapFunctional(c.default || c))
export const PrivacyPolicyParagraphs = () => import('../../components/privacy-policy/Paragraphs.vue' /* webpackChunkName: "components/privacy-policy-paragraphs" */).then(c => wrapFunctional(c.default || c))
export const ProfileContinuesBlock = () => import('../../components/profile/ContinuesBlock.vue' /* webpackChunkName: "components/profile-continues-block" */).then(c => wrapFunctional(c.default || c))
export const ProfileContinuesTab = () => import('../../components/profile/ContinuesTab.vue' /* webpackChunkName: "components/profile-continues-tab" */).then(c => wrapFunctional(c.default || c))
export const ProfileOfferCard = () => import('../../components/profile/OfferCard.vue' /* webpackChunkName: "components/profile-offer-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileOwsPopup = () => import('../../components/profile/OwsPopup.vue' /* webpackChunkName: "components/profile-ows-popup" */).then(c => wrapFunctional(c.default || c))
export const ProfileAddGradeElement = () => import('../../components/profile_add/GradeElement.vue' /* webpackChunkName: "components/profile-add-grade-element" */).then(c => wrapFunctional(c.default || c))
export const ProfileAddGradeSelector = () => import('../../components/profile_add/GradeSelector.vue' /* webpackChunkName: "components/profile-add-grade-selector" */).then(c => wrapFunctional(c.default || c))
export const ProfileAddPersonalContactData = () => import('../../components/profile_add/PersonalContactData.vue' /* webpackChunkName: "components/profile-add-personal-contact-data" */).then(c => wrapFunctional(c.default || c))
export const ProfileAddPersonalData = () => import('../../components/profile_add/PersonalData.vue' /* webpackChunkName: "components/profile-add-personal-data" */).then(c => wrapFunctional(c.default || c))
export const ProfileSettingsPersonalContactData = () => import('../../components/profile_settings/PersonalContactData.vue' /* webpackChunkName: "components/profile-settings-personal-contact-data" */).then(c => wrapFunctional(c.default || c))
export const ProfileSettingsPersonalData = () => import('../../components/profile_settings/PersonalData.vue' /* webpackChunkName: "components/profile-settings-personal-data" */).then(c => wrapFunctional(c.default || c))
export const RegisterStepper = () => import('../../components/register/RegisterStepper.vue' /* webpackChunkName: "components/register-stepper" */).then(c => wrapFunctional(c.default || c))
export const RegisterStepper2 = () => import('../../components/register/RegisterStepper2.vue' /* webpackChunkName: "components/register-stepper2" */).then(c => wrapFunctional(c.default || c))
export const RenewalsBookRenewalCard = () => import('../../components/renewals/BookRenewalCard.vue' /* webpackChunkName: "components/renewals-book-renewal-card" */).then(c => wrapFunctional(c.default || c))
export const RenewalsExploreGroupOfferBanner = () => import('../../components/renewals/ExploreGroupOfferBanner.vue' /* webpackChunkName: "components/renewals-explore-group-offer-banner" */).then(c => wrapFunctional(c.default || c))
export const RenewalsPrimarySchoolEndCard = () => import('../../components/renewals/PrimarySchoolEndCard.vue' /* webpackChunkName: "components/renewals-primary-school-end-card" */).then(c => wrapFunctional(c.default || c))
export const RenewalsRenewalBanner = () => import('../../components/renewals/RenewalBanner.vue' /* webpackChunkName: "components/renewals-renewal-banner" */).then(c => wrapFunctional(c.default || c))
export const RenewalsRenewalCard = () => import('../../components/renewals/RenewalCard.vue' /* webpackChunkName: "components/renewals-renewal-card" */).then(c => wrapFunctional(c.default || c))
export const RenewalsRenewalSidebar = () => import('../../components/renewals/RenewalSidebar.vue' /* webpackChunkName: "components/renewals-renewal-sidebar" */).then(c => wrapFunctional(c.default || c))
export const RenewalsRenewalTimer = () => import('../../components/renewals/RenewalTimer.vue' /* webpackChunkName: "components/renewals-renewal-timer" */).then(c => wrapFunctional(c.default || c))
export const RenewalsRenewalTimer2 = () => import('../../components/renewals/RenewalTimer2.vue' /* webpackChunkName: "components/renewals-renewal-timer2" */).then(c => wrapFunctional(c.default || c))
export const RoundButton = () => import('../../components/round_button/RoundButton.vue' /* webpackChunkName: "components/round-button" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMap = () => import('../../components/schools_map/Map.vue' /* webpackChunkName: "components/schools-map" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMapProfiBanner = () => import('../../components/schools_map/ProfiBanner.vue' /* webpackChunkName: "components/schools-map-profi-banner" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMapReportSchoolForm = () => import('../../components/schools_map/ReportSchoolForm.vue' /* webpackChunkName: "components/schools-map-report-school-form" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMapSchoolFilter = () => import('../../components/schools_map/SchoolFilter.vue' /* webpackChunkName: "components/schools-map-school-filter" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMapSchoolServiceCard = () => import('../../components/schools_map/SchoolServiceCard.vue' /* webpackChunkName: "components/schools-map-school-service-card" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMapSchoolServices = () => import('../../components/schools_map/SchoolServices.vue' /* webpackChunkName: "components/schools-map-school-services" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMapSelectSchoolBanner = () => import('../../components/schools_map/SelectSchoolBanner.vue' /* webpackChunkName: "components/schools-map-select-school-banner" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMapSummaryReport = () => import('../../components/schools_map/SummaryReport.vue' /* webpackChunkName: "components/schools-map-summary-report" */).then(c => wrapFunctional(c.default || c))
export const SchoolsMapToolbar = () => import('../../components/schools_map/Toolbar.vue' /* webpackChunkName: "components/schools-map-toolbar" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadOfferAskAboutServiceForm = () => import('../../components/service_lead_offer/AskAboutServiceForm.vue' /* webpackChunkName: "components/service-lead-offer-ask-about-service-form" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadOfferBenefitBanner = () => import('../../components/service_lead_offer/BenefitBanner.vue' /* webpackChunkName: "components/service-lead-offer-benefit-banner" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadOfferFooter = () => import('../../components/service_lead_offer/Footer.vue' /* webpackChunkName: "components/service-lead-offer-footer" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadOfferGroupCard = () => import('../../components/service_lead_offer/GroupCard.vue' /* webpackChunkName: "components/service-lead-offer-group-card" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadBanner = () => import('../../components/service_lead_offer/LeadBanner.vue' /* webpackChunkName: "components/service-lead-banner" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadBannerLanding = () => import('../../components/service_lead_offer/LeadBannerLanding.vue' /* webpackChunkName: "components/service-lead-banner-landing" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadBannerSuccess = () => import('../../components/service_lead_offer/LeadBannerSuccess.vue' /* webpackChunkName: "components/service-lead-banner-success" */).then(c => wrapFunctional(c.default || c))
export const ServiceDialog = () => import('../../components/service_lead_offer/ServiceDialog.vue' /* webpackChunkName: "components/service-dialog" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadOfferToolbar = () => import('../../components/service_lead_offer/Toolbar.vue' /* webpackChunkName: "components/service-lead-offer-toolbar" */).then(c => wrapFunctional(c.default || c))
export const ServiceLeadOfferVideoCard = () => import('../../components/service_lead_offer/VideoCard.vue' /* webpackChunkName: "components/service-lead-offer-video-card" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferAboutTrialLesson = () => import('../../components/service_offer/AboutTrialLesson.vue' /* webpackChunkName: "components/service-offer-about-trial-lesson" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferAfterCourse = () => import('../../components/service_offer/AfterCourse.vue' /* webpackChunkName: "components/service-offer-after-course" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferComingSoonNotify = () => import('../../components/service_offer/ComingSoonNotify.vue' /* webpackChunkName: "components/service-offer-coming-soon-notify" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferCommentSection = () => import('../../components/service_offer/CommentSection.vue' /* webpackChunkName: "components/service-offer-comment-section" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferCourseInfoCards = () => import('../../components/service_offer/CourseInfoCards.vue' /* webpackChunkName: "components/service-offer-course-info-cards" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferCoursePrice = () => import('../../components/service_offer/CoursePrice.vue' /* webpackChunkName: "components/service-offer-course-price" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferEnglishCertificates = () => import('../../components/service_offer/EnglishCertificates.vue' /* webpackChunkName: "components/service-offer-english-certificates" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferFaq = () => import('../../components/service_offer/Faq.vue' /* webpackChunkName: "components/service-offer-faq" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferFreeEbook = () => import('../../components/service_offer/FreeEbook.vue' /* webpackChunkName: "components/service-offer-free-ebook" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferGroupCard = () => import('../../components/service_offer/GroupCard.vue' /* webpackChunkName: "components/service-offer-group-card" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferIndividualCard = () => import('../../components/service_offer/IndividualCard.vue' /* webpackChunkName: "components/service-offer-individual-card" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferLessonsInfo = () => import('../../components/service_offer/LessonsInfo.vue' /* webpackChunkName: "components/service-offer-lessons-info" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferHeaderBanner = () => import('../../components/service_offer/OfferHeaderBanner.vue' /* webpackChunkName: "components/service-offer-header-banner" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferOurStudentsArts = () => import('../../components/service_offer/OurStudentsArts.vue' /* webpackChunkName: "components/service-offer-our-students-arts" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferRating = () => import('../../components/service_offer/Rating.vue' /* webpackChunkName: "components/service-offer-rating" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferSchedule = () => import('../../components/service_offer/Schedule.vue' /* webpackChunkName: "components/service-offer-schedule" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferScienceBlock = () => import('../../components/service_offer/ScienceBlock.vue' /* webpackChunkName: "components/service-offer-science-block" */).then(c => wrapFunctional(c.default || c))
export const ServiceContains = () => import('../../components/service_offer/ServiceContains.vue' /* webpackChunkName: "components/service-contains" */).then(c => wrapFunctional(c.default || c))
export const ServiceDetail = () => import('../../components/service_offer/ServiceDetail.vue' /* webpackChunkName: "components/service-detail" */).then(c => wrapFunctional(c.default || c))
export const ServiceForProfileBlock = () => import('../../components/service_offer/ServiceForProfileBlock.vue' /* webpackChunkName: "components/service-for-profile-block" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferTrainingMethods = () => import('../../components/service_offer/TrainingMethods.vue' /* webpackChunkName: "components/service-offer-training-methods" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferTrainingProfits = () => import('../../components/service_offer/TrainingProfits.vue' /* webpackChunkName: "components/service-offer-training-profits" */).then(c => wrapFunctional(c.default || c))
export const ServiceOfferWonderVideo = () => import('../../components/service_offer/WonderVideo.vue' /* webpackChunkName: "components/service-offer-wonder-video" */).then(c => wrapFunctional(c.default || c))
export const SettingsBanner = () => import('../../components/settings/Banner.vue' /* webpackChunkName: "components/settings-banner" */).then(c => wrapFunctional(c.default || c))
export const SettingsPasswordEdit = () => import('../../components/settings/PasswordEdit.vue' /* webpackChunkName: "components/settings-password-edit" */).then(c => wrapFunctional(c.default || c))
export const SettingsPersonalAddressData = () => import('../../components/settings/PersonalAddressData.vue' /* webpackChunkName: "components/settings-personal-address-data" */).then(c => wrapFunctional(c.default || c))
export const SettingsPersonalData = () => import('../../components/settings/PersonalData.vue' /* webpackChunkName: "components/settings-personal-data" */).then(c => wrapFunctional(c.default || c))
export const SettingsPinInformationBlock = () => import('../../components/settings/PinInformationBlock.vue' /* webpackChunkName: "components/settings-pin-information-block" */).then(c => wrapFunctional(c.default || c))
export const TabsAvailabilityTab = () => import('../../components/tabs/AvailabilityTab.vue' /* webpackChunkName: "components/tabs-availability-tab" */).then(c => wrapFunctional(c.default || c))
export const TabsCertificateTab = () => import('../../components/tabs/CertificateTab.vue' /* webpackChunkName: "components/tabs-certificate-tab" */).then(c => wrapFunctional(c.default || c))
export const TabsEducationTab = () => import('../../components/tabs/EducationTab.vue' /* webpackChunkName: "components/tabs-education-tab" */).then(c => wrapFunctional(c.default || c))
export const TabsExperienceTab = () => import('../../components/tabs/ExperienceTab.vue' /* webpackChunkName: "components/tabs-experience-tab" */).then(c => wrapFunctional(c.default || c))
export const TabsLessonsTab = () => import('../../components/tabs/LessonsTab.vue' /* webpackChunkName: "components/tabs-lessons-tab" */).then(c => wrapFunctional(c.default || c))
export const TeacherFormDateRowForm = () => import('../../components/teacherForm/DateRowForm.vue' /* webpackChunkName: "components/teacher-form-date-row-form" */).then(c => wrapFunctional(c.default || c))
export const TeacherFormFileUploader = () => import('../../components/teacherForm/FileUploader.vue' /* webpackChunkName: "components/teacher-form-file-uploader" */).then(c => wrapFunctional(c.default || c))
export const TeacherFormPortfolioUploader = () => import('../../components/teacherForm/PortfolioUploader.vue' /* webpackChunkName: "components/teacher-form-portfolio-uploader" */).then(c => wrapFunctional(c.default || c))
export const TeacherFormVideoUploader = () => import('../../components/teacherForm/VideoUploader.vue' /* webpackChunkName: "components/teacher-form-video-uploader" */).then(c => wrapFunctional(c.default || c))
export const TeacherFormDateRow = () => import('../../components/teacher_form/DateRow.vue' /* webpackChunkName: "components/teacher-form-date-row" */).then(c => wrapFunctional(c.default || c))
export const TeacherPaymentsEmptyState = () => import('../../components/teacher_payments/EmptyState.vue' /* webpackChunkName: "components/teacher-payments-empty-state" */).then(c => wrapFunctional(c.default || c))
export const TeacherPaymentsBlock = () => import('../../components/teacher_payments/PaymentsBlock.vue' /* webpackChunkName: "components/teacher-payments-block" */).then(c => wrapFunctional(c.default || c))
export const TeacherPaymentsGroupFilter = () => import('../../components/teacher_payments/PaymentsGroupFilter.vue' /* webpackChunkName: "components/teacher-payments-group-filter" */).then(c => wrapFunctional(c.default || c))
export const TeacherPaymentsIndividualFilter = () => import('../../components/teacher_payments/PaymentsIndividualFilter.vue' /* webpackChunkName: "components/teacher-payments-individual-filter" */).then(c => wrapFunctional(c.default || c))
export const TeacherPaymentsTable = () => import('../../components/teacher_payments/PaymentsTable.vue' /* webpackChunkName: "components/teacher-payments-table" */).then(c => wrapFunctional(c.default || c))
export const TeacherPaymentsSmallerBanner = () => import('../../components/teacher_payments/SmallerBanner.vue' /* webpackChunkName: "components/teacher-payments-smaller-banner" */).then(c => wrapFunctional(c.default || c))
export const TeacherPersonalData = () => import('../../components/teacher_personal_data/PersonalData.vue' /* webpackChunkName: "components/teacher-personal-data" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileEditAddItemBtn = () => import('../../components/teacher_profile_edit/AddItemBtn.vue' /* webpackChunkName: "components/teacher-profile-edit-add-item-btn" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileEditBlock = () => import('../../components/teacher_profile_edit/EditBlock.vue' /* webpackChunkName: "components/teacher-profile-edit-block" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileEditInformationBlock = () => import('../../components/teacher_profile_edit/InformationBlock.vue' /* webpackChunkName: "components/teacher-profile-edit-information-block" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileAvailabilityData = () => import('../../components/teacher_profile_edit/ProfileAvailabilityData.vue' /* webpackChunkName: "components/teacher-profile-availability-data" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileData = () => import('../../components/teacher_profile_edit/ProfileData.vue' /* webpackChunkName: "components/teacher-profile-data" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileDescriptionData = () => import('../../components/teacher_profile_edit/ProfileDescriptionData.vue' /* webpackChunkName: "components/teacher-profile-description-data" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileMainData = () => import('../../components/teacher_profile_edit/ProfileMainData.vue' /* webpackChunkName: "components/teacher-profile-main-data" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileSkillsData = () => import('../../components/teacher_profile_edit/ProfileSkillsData.vue' /* webpackChunkName: "components/teacher-profile-skills-data" */).then(c => wrapFunctional(c.default || c))
export const TeacherProfileEditSkillsBlock = () => import('../../components/teacher_profile_edit/SkillsBlock.vue' /* webpackChunkName: "components/teacher-profile-edit-skills-block" */).then(c => wrapFunctional(c.default || c))
export const TestLeadGroupCardOrderContact = () => import('../../components/test_lead/GroupCardOrderContact.vue' /* webpackChunkName: "components/test-lead-group-card-order-contact" */).then(c => wrapFunctional(c.default || c))
export const CoursesRevampLessonCardChips = () => import('../../components/courses/revamp/LessonCardChips.vue' /* webpackChunkName: "components/courses-revamp-lesson-card-chips" */).then(c => wrapFunctional(c.default || c))
export const DashboardArchivedCourseEmptyState = () => import('../../components/dashboard/archived/ArchivedCourseEmptyState.vue' /* webpackChunkName: "components/dashboard-archived-course-empty-state" */).then(c => wrapFunctional(c.default || c))
export const DashboardArchivedInformationBlock = () => import('../../components/dashboard/archived/ArchivedInformationBlock.vue' /* webpackChunkName: "components/dashboard-archived-information-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardArchivedCourseDetailCard = () => import('../../components/dashboard/archived/CourseDetailCard.vue' /* webpackChunkName: "components/dashboard-archived-course-detail-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardArchivedDiplomaCard = () => import('../../components/dashboard/archived/DiplomaCard.vue' /* webpackChunkName: "components/dashboard-archived-diploma-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardArchivedEvaluationBlock = () => import('../../components/dashboard/archived/EvaluationBlock.vue' /* webpackChunkName: "components/dashboard-archived-evaluation-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardArchivedEvaluationEmptyState = () => import('../../components/dashboard/archived/EvaluationEmptyState.vue' /* webpackChunkName: "components/dashboard-archived-evaluation-empty-state" */).then(c => wrapFunctional(c.default || c))
export const DashboardArchivedProgressCard = () => import('../../components/dashboard/archived/ProgressCard.vue' /* webpackChunkName: "components/dashboard-archived-progress-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardCalendarChangeAvailabilityDialog = () => import('../../components/dashboard/calendar/ChangeAvailabilityDialog.vue' /* webpackChunkName: "components/dashboard-calendar-change-availability-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardCalendarEmptyState = () => import('../../components/dashboard/calendar/EmptyState.vue' /* webpackChunkName: "components/dashboard-calendar-empty-state" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationsAnswer = () => import('../../components/dashboard/evaluations/Answer.vue' /* webpackChunkName: "components/dashboard-evaluations-answer" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationsBottomBar = () => import('../../components/dashboard/evaluations/BottomBar.vue' /* webpackChunkName: "components/dashboard-evaluations-bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationsMobileInfo = () => import('../../components/dashboard/evaluations/MobileInfo.vue' /* webpackChunkName: "components/dashboard-evaluations-mobile-info" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationsQuestion = () => import('../../components/dashboard/evaluations/Question.vue' /* webpackChunkName: "components/dashboard-evaluations-question" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationsStatus = () => import('../../components/dashboard/evaluations/Status.vue' /* webpackChunkName: "components/dashboard-evaluations-status" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationsSummary = () => import('../../components/dashboard/evaluations/Summary.vue' /* webpackChunkName: "components/dashboard-evaluations-summary" */).then(c => wrapFunctional(c.default || c))
export const DashboardEvaluationsTopBar = () => import('../../components/dashboard/evaluations/TopBar.vue' /* webpackChunkName: "components/dashboard-evaluations-top-bar" */).then(c => wrapFunctional(c.default || c))
export const DashboardHolidaysBottomBar = () => import('../../components/dashboard/holidays/BottomBar.vue' /* webpackChunkName: "components/dashboard-holidays-bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const DashboardHolidaysHolidayBreakSuccess = () => import('../../components/dashboard/holidays/HolidayBreakSuccess.vue' /* webpackChunkName: "components/dashboard-holidays-holiday-break-success" */).then(c => wrapFunctional(c.default || c))
export const DashboardHolidaysHolidayInfoBanner = () => import('../../components/dashboard/holidays/HolidayInfoBanner.vue' /* webpackChunkName: "components/dashboard-holidays-holiday-info-banner" */).then(c => wrapFunctional(c.default || c))
export const DashboardHolidaysHolidayInfoCard = () => import('../../components/dashboard/holidays/HolidayInfoCard.vue' /* webpackChunkName: "components/dashboard-holidays-holiday-info-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardHolidaysSendHolidayBreak = () => import('../../components/dashboard/holidays/SendHolidayBreak.vue' /* webpackChunkName: "components/dashboard-holidays-send-holiday-break" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonMaterialDescriptionCard = () => import('../../components/dashboard/lesson_material/DescriptionCard.vue' /* webpackChunkName: "components/dashboard-lesson-material-description-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonMaterialStudentEmptyState = () => import('../../components/dashboard/lesson_material_student/EmptyState.vue' /* webpackChunkName: "components/dashboard-lesson-material-student-empty-state" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonMaterialCard = () => import('../../components/dashboard/lesson_material_student/MaterialCard.vue' /* webpackChunkName: "components/dashboard-lesson-material-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonMaterialStudentWorkcard = () => import('../../components/dashboard/lesson_material_student/Workcard.vue' /* webpackChunkName: "components/dashboard-lesson-material-student-workcard" */).then(c => wrapFunctional(c.default || c))
export const DashboardLessonMaterialStudentWorkcardIndividual = () => import('../../components/dashboard/lesson_material_student/WorkcardIndividual.vue' /* webpackChunkName: "components/dashboard-lesson-material-student-workcard-individual" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingChangeProgrammeModal = () => import('../../components/dashboard/rating/ChangeProgrammeModal.vue' /* webpackChunkName: "components/dashboard-rating-change-programme-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingChangeTopicModal = () => import('../../components/dashboard/rating/ChangeTopicModal.vue' /* webpackChunkName: "components/dashboard-rating-change-topic-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingCompliment = () => import('../../components/dashboard/rating/Compliment.vue' /* webpackChunkName: "components/dashboard-rating-compliment" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingComplimentIndividual = () => import('../../components/dashboard/rating/ComplimentIndividual.vue' /* webpackChunkName: "components/dashboard-rating-compliment-individual" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingDataTable = () => import('../../components/dashboard/rating/DataTable.vue' /* webpackChunkName: "components/dashboard-rating-data-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingHomework = () => import('../../components/dashboard/rating/Homework.vue' /* webpackChunkName: "components/dashboard-rating-homework" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingHomeworkIndividual = () => import('../../components/dashboard/rating/HomeworkIndividual.vue' /* webpackChunkName: "components/dashboard-rating-homework-individual" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingLessonMaterials = () => import('../../components/dashboard/rating/LessonMaterials.vue' /* webpackChunkName: "components/dashboard-rating-lesson-materials" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingLessonTopicAndStatus = () => import('../../components/dashboard/rating/LessonTopicAndStatus.vue' /* webpackChunkName: "components/dashboard-rating-lesson-topic-and-status" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingMaterialsForStudent = () => import('../../components/dashboard/rating/MaterialsForStudent.vue' /* webpackChunkName: "components/dashboard-rating-materials-for-student" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingNextLessonPlan = () => import('../../components/dashboard/rating/NextLessonPlan.vue' /* webpackChunkName: "components/dashboard-rating-next-lesson-plan" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingRate = () => import('../../components/dashboard/rating/Rate.vue' /* webpackChunkName: "components/dashboard-rating-rate" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingRateStudents = () => import('../../components/dashboard/rating/RateStudents.vue' /* webpackChunkName: "components/dashboard-rating-rate-students" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingRateStudentsIndividual = () => import('../../components/dashboard/rating/RateStudentsIndividual.vue' /* webpackChunkName: "components/dashboard-rating-rate-students-individual" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingLessonCard = () => import('../../components/dashboard/rating/RatingLessonCard.vue' /* webpackChunkName: "components/dashboard-rating-lesson-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingTeacherLessonCard = () => import('../../components/dashboard/rating/RatingTeacherLessonCard.vue' /* webpackChunkName: "components/dashboard-rating-teacher-lesson-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingTeacherLessonCardIndividual = () => import('../../components/dashboard/rating/RatingTeacherLessonCardIndividual.vue' /* webpackChunkName: "components/dashboard-rating-teacher-lesson-card-individual" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingTeacherLessonCardIndividualTrial = () => import('../../components/dashboard/rating/RatingTeacherLessonCardIndividualTrial.vue' /* webpackChunkName: "components/dashboard-rating-teacher-lesson-card-individual-trial" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingTeacherLessonCardSimple = () => import('../../components/dashboard/rating/RatingTeacherLessonCardSimple.vue' /* webpackChunkName: "components/dashboard-rating-teacher-lesson-card-simple" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingReportStudent = () => import('../../components/dashboard/rating/ReportStudent.vue' /* webpackChunkName: "components/dashboard-rating-report-student" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingTopicStatusTable = () => import('../../components/dashboard/rating/TopicStatusTable.vue' /* webpackChunkName: "components/dashboard-rating-topic-status-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingTopicStatusTableMobile = () => import('../../components/dashboard/rating/TopicStatusTableMobile.vue' /* webpackChunkName: "components/dashboard-rating-topic-status-table-mobile" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingTopicStatusTableRow = () => import('../../components/dashboard/rating/TopicStatusTableRow.vue' /* webpackChunkName: "components/dashboard-rating-topic-status-table-row" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampBookLesson = () => import('../../components/dashboard/revamp/BookLesson.vue' /* webpackChunkName: "components/dashboard-revamp-book-lesson" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampCalendar = () => import('../../components/dashboard/revamp/Calendar.vue' /* webpackChunkName: "components/dashboard-revamp-calendar" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampCalendarAside = () => import('../../components/dashboard/revamp/CalendarAside.vue' /* webpackChunkName: "components/dashboard-revamp-calendar-aside" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampCalendarSchedule = () => import('../../components/dashboard/revamp/CalendarSchedule.vue' /* webpackChunkName: "components/dashboard-revamp-calendar-schedule" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampCalendarSettings = () => import('../../components/dashboard/revamp/CalendarSettings.vue' /* webpackChunkName: "components/dashboard-revamp-calendar-settings" */).then(c => wrapFunctional(c.default || c))
export const DashboardClassesCanceledSlot = () => import('../../components/dashboard/revamp/DashboardClassesCanceledSlot.vue' /* webpackChunkName: "components/dashboard-classes-canceled-slot" */).then(c => wrapFunctional(c.default || c))
export const DashboardCourseInfo = () => import('../../components/dashboard/revamp/DashboardCourseInfo.vue' /* webpackChunkName: "components/dashboard-course-info" */).then(c => wrapFunctional(c.default || c))
export const DashboardCourseInfoStudent = () => import('../../components/dashboard/revamp/DashboardCourseInfoStudent.vue' /* webpackChunkName: "components/dashboard-course-info-student" */).then(c => wrapFunctional(c.default || c))
export const DashboardCourseInfoTeacher = () => import('../../components/dashboard/revamp/DashboardCourseInfoTeacher.vue' /* webpackChunkName: "components/dashboard-course-info-teacher" */).then(c => wrapFunctional(c.default || c))
export const DashboardCoursePlan = () => import('../../components/dashboard/revamp/DashboardCoursePlan.vue' /* webpackChunkName: "components/dashboard-course-plan" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampDetailLessonDialogAction = () => import('../../components/dashboard/revamp/DetailLessonDialogAction.vue' /* webpackChunkName: "components/dashboard-revamp-detail-lesson-dialog-action" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampEmptyState = () => import('../../components/dashboard/revamp/EmptyState.vue' /* webpackChunkName: "components/dashboard-revamp-empty-state" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampIndividualCard = () => import('../../components/dashboard/revamp/IndividualCard.vue' /* webpackChunkName: "components/dashboard-revamp-individual-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampIndividualMaterialCard = () => import('../../components/dashboard/revamp/IndividualMaterialCard.vue' /* webpackChunkName: "components/dashboard-revamp-individual-material-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampIndividualStudentActiveCourse = () => import('../../components/dashboard/revamp/IndividualStudentActiveCourse.vue' /* webpackChunkName: "components/dashboard-revamp-individual-student-active-course" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampIndividualStudentCard = () => import('../../components/dashboard/revamp/IndividualStudentCard.vue' /* webpackChunkName: "components/dashboard-revamp-individual-student-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampIndividualStudentDetails = () => import('../../components/dashboard/revamp/IndividualStudentDetails.vue' /* webpackChunkName: "components/dashboard-revamp-individual-student-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampIndividualStudentFormDetails = () => import('../../components/dashboard/revamp/IndividualStudentFormDetails.vue' /* webpackChunkName: "components/dashboard-revamp-individual-student-form-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampIndividualTopicCard = () => import('../../components/dashboard/revamp/IndividualTopicCard.vue' /* webpackChunkName: "components/dashboard-revamp-individual-topic-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampInfoCard = () => import('../../components/dashboard/revamp/InfoCard.vue' /* webpackChunkName: "components/dashboard-revamp-info-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampLessonCancellationDialog = () => import('../../components/dashboard/revamp/LessonCancellationDialog.vue' /* webpackChunkName: "components/dashboard-revamp-lesson-cancellation-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampLessonCancellationModal = () => import('../../components/dashboard/revamp/LessonCancellationModal.vue' /* webpackChunkName: "components/dashboard-revamp-lesson-cancellation-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampLessonHomework = () => import('../../components/dashboard/revamp/LessonHomework.vue' /* webpackChunkName: "components/dashboard-revamp-lesson-homework" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampLessonPresence = () => import('../../components/dashboard/revamp/LessonPresence.vue' /* webpackChunkName: "components/dashboard-revamp-lesson-presence" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampMaterialServiceCard = () => import('../../components/dashboard/revamp/MaterialServiceCard.vue' /* webpackChunkName: "components/dashboard-revamp-material-service-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampNewestLessonCard = () => import('../../components/dashboard/revamp/NewestLessonCard.vue' /* webpackChunkName: "components/dashboard-revamp-newest-lesson-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampNewestLessonTimer = () => import('../../components/dashboard/revamp/NewestLessonTimer.vue' /* webpackChunkName: "components/dashboard-revamp-newest-lesson-timer" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampNoteCard = () => import('../../components/dashboard/revamp/NoteCard.vue' /* webpackChunkName: "components/dashboard-revamp-note-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampTeacherAbsence = () => import('../../components/dashboard/revamp/TeacherAbsence.vue' /* webpackChunkName: "components/dashboard-revamp-teacher-absence" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampTeacherAbsenceCard = () => import('../../components/dashboard/revamp/TeacherAbsenceCard.vue' /* webpackChunkName: "components/dashboard-revamp-teacher-absence-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampTeacherAbsenceConfirmDialog = () => import('../../components/dashboard/revamp/TeacherAbsenceConfirmDialog.vue' /* webpackChunkName: "components/dashboard-revamp-teacher-absence-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampTeacherAbsenceDeleteDialog = () => import('../../components/dashboard/revamp/TeacherAbsenceDeleteDialog.vue' /* webpackChunkName: "components/dashboard-revamp-teacher-absence-delete-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampTeacherAbsenceEditDialog = () => import('../../components/dashboard/revamp/TeacherAbsenceEditDialog.vue' /* webpackChunkName: "components/dashboard-revamp-teacher-absence-edit-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampTeacherIndividualArchived = () => import('../../components/dashboard/revamp/TeacherIndividualArchived.vue' /* webpackChunkName: "components/dashboard-revamp-teacher-individual-archived" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampTeacherIndividualMaterialsDetail = () => import('../../components/dashboard/revamp/TeacherIndividualMaterialsDetail.vue' /* webpackChunkName: "components/dashboard-revamp-teacher-individual-materials-detail" */).then(c => wrapFunctional(c.default || c))
export const DashboardRevampWorkCard = () => import('../../components/dashboard/revamp/WorkCard.vue' /* webpackChunkName: "components/dashboard-revamp-work-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeAvailableTopicBlock = () => import('../../components/dashboard/topic_change/AvailableTopicBlock.vue' /* webpackChunkName: "components/dashboard-topic-change-available-topic-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeAvailableTopicEmptyState = () => import('../../components/dashboard/topic_change/AvailableTopicEmptyState.vue' /* webpackChunkName: "components/dashboard-topic-change-available-topic-empty-state" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeInfoBanner = () => import('../../components/dashboard/topic_change/InfoBanner.vue' /* webpackChunkName: "components/dashboard-topic-change-info-banner" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeInfoBannerDesktop = () => import('../../components/dashboard/topic_change/InfoBannerDesktop.vue' /* webpackChunkName: "components/dashboard-topic-change-info-banner-desktop" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeInfoBannerMobile = () => import('../../components/dashboard/topic_change/InfoBannerMobile.vue' /* webpackChunkName: "components/dashboard-topic-change-info-banner-mobile" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeLessonData = () => import('../../components/dashboard/topic_change/LessonData.vue' /* webpackChunkName: "components/dashboard-topic-change-lesson-data" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeLessonMaterialDetail = () => import('../../components/dashboard/topic_change/LessonMaterialDetail.vue' /* webpackChunkName: "components/dashboard-topic-change-lesson-material-detail" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeLessonNoteCard = () => import('../../components/dashboard/topic_change/LessonNoteCard.vue' /* webpackChunkName: "components/dashboard-topic-change-lesson-note-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeMaterialData = () => import('../../components/dashboard/topic_change/MaterialData.vue' /* webpackChunkName: "components/dashboard-topic-change-material-data" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangePresentationTable = () => import('../../components/dashboard/topic_change/PresentationTable.vue' /* webpackChunkName: "components/dashboard-topic-change-presentation-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangePresentationTableRow = () => import('../../components/dashboard/topic_change/PresentationTableRow.vue' /* webpackChunkName: "components/dashboard-topic-change-presentation-table-row" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeSelectedTopicBlock = () => import('../../components/dashboard/topic_change/SelectedTopicBlock.vue' /* webpackChunkName: "components/dashboard-topic-change-selected-topic-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangeSelectedTopicElement = () => import('../../components/dashboard/topic_change/SelectedTopicElement.vue' /* webpackChunkName: "components/dashboard-topic-change-selected-topic-element" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicDetailCard = () => import('../../components/dashboard/topic_change/TopicDetailCard.vue' /* webpackChunkName: "components/dashboard-topic-detail-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicDetailCardToolbar = () => import('../../components/dashboard/topic_change/TopicDetailCardToolbar.vue' /* webpackChunkName: "components/dashboard-topic-detail-card-toolbar" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicDetailDialog = () => import('../../components/dashboard/topic_change/TopicDetailDialog.vue' /* webpackChunkName: "components/dashboard-topic-detail-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicMaterialDetail = () => import('../../components/dashboard/topic_change/TopicMaterialDetail.vue' /* webpackChunkName: "components/dashboard-topic-material-detail" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicStatusChip = () => import('../../components/dashboard/topic_change/TopicStatusChip.vue' /* webpackChunkName: "components/dashboard-topic-status-chip" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicTable = () => import('../../components/dashboard/topic_change/TopicTable.vue' /* webpackChunkName: "components/dashboard-topic-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicTableDesktop = () => import('../../components/dashboard/topic_change/TopicTableDesktop.vue' /* webpackChunkName: "components/dashboard-topic-table-desktop" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicTableMobile = () => import('../../components/dashboard/topic_change/TopicTableMobile.vue' /* webpackChunkName: "components/dashboard-topic-table-mobile" */).then(c => wrapFunctional(c.default || c))
export const HelpMaintenanceCard = () => import('../../components/help/maintenance/MaintenanceCard.vue' /* webpackChunkName: "components/help-maintenance-card" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../../components/home/Banner/Banner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJobsOffertJobOfferHeaderBanner = () => import('../../components/home_teacher/jobs_offert/JobOfferHeaderBanner.vue' /* webpackChunkName: "components/home-teacher-jobs-offert-job-offer-header-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJobsOffertJobOfferTable = () => import('../../components/home_teacher/jobs_offert/JobOfferTable.vue' /* webpackChunkName: "components/home-teacher-jobs-offert-job-offer-table" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsBenefits = () => import('../../components/home_teacher/join_us/Benefits.vue' /* webpackChunkName: "components/home-teacher-join-us-benefits" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsHeaderBanner = () => import('../../components/home_teacher/join_us/HeaderBanner.vue' /* webpackChunkName: "components/home-teacher-join-us-header-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsHeaderMenu = () => import('../../components/home_teacher/join_us/HeaderMenu.vue' /* webpackChunkName: "components/home-teacher-join-us-header-menu" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsJobsOffert = () => import('../../components/home_teacher/join_us/JobsOffert.vue' /* webpackChunkName: "components/home-teacher-join-us-jobs-offert" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsTeachDesc = () => import('../../components/home_teacher/join_us/TeachDesc.vue' /* webpackChunkName: "components/home-teacher-join-us-teach-desc" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferralServiceBlock = () => import('../../components/member_gets_member/referral/ServiceBlock.vue' /* webpackChunkName: "components/member-gets-member-referral-service-block" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferralInvitedComments = () => import('../../components/member_gets_member/referral_invited/Comments.vue' /* webpackChunkName: "components/member-gets-member-referral-invited-comments" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferralInvitedOpinionSection = () => import('../../components/member_gets_member/referral_invited/OpinionSection.vue' /* webpackChunkName: "components/member-gets-member-referral-invited-opinion-section" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferralInvitedPromoCardInvited = () => import('../../components/member_gets_member/referral_invited/PromoCardInvited.vue' /* webpackChunkName: "components/member-gets-member-referral-invited-promo-card-invited" */).then(c => wrapFunctional(c.default || c))
export const MemberGetsMemberReferralInvitedServiceBlock = () => import('../../components/member_gets_member/referral_invited/ServiceBlock.vue' /* webpackChunkName: "components/member-gets-member-referral-invited-service-block" */).then(c => wrapFunctional(c.default || c))
export const DashboardRatingComponentsLessonTopicAndStatusStep = () => import('../../components/dashboard/rating/components/LessonTopicAndStatusStep.vue' /* webpackChunkName: "components/dashboard-rating-components-lesson-topic-and-status-step" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangePresentationTableDesktop = () => import('../../components/dashboard/topic_change/presentation_table/PresentationTableDesktop.vue' /* webpackChunkName: "components/dashboard-topic-change-presentation-table-desktop" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopicChangePresentationTableMobile = () => import('../../components/dashboard/topic_change/presentation_table/PresentationTableMobile.vue' /* webpackChunkName: "components/dashboard-topic-change-presentation-table-mobile" */).then(c => wrapFunctional(c.default || c))
export const HomeBannerRedirectCard = () => import('../../components/home/Banner/RedirectCard/RedirectCard.vue' /* webpackChunkName: "components/home-banner-redirect-card" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsJobOffer = () => import('../../components/home_teacher/join_us/job/JobOffer.vue' /* webpackChunkName: "components/home-teacher-join-us-job-offer" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsTeachCardBenefit = () => import('../../components/home_teacher/join_us/teach_card/Benefit.vue' /* webpackChunkName: "components/home-teacher-join-us-teach-card-benefit" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsTeachCard = () => import('../../components/home_teacher/join_us/teach_card/TeachCard.vue' /* webpackChunkName: "components/home-teacher-join-us-teach-card" */).then(c => wrapFunctional(c.default || c))
export const HomeTeacherJoinUsTeachCardTechBigCard = () => import('../../components/home_teacher/join_us/teach_card/TechBigCard.vue' /* webpackChunkName: "components/home-teacher-join-us-teach-card-tech-big-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
